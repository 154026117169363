import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Logo from '../assest/images/37a39cb7092d94d7297953ad09f2dee5.png';
import { FaPhone } from "react-icons/fa6";
import { HiUserAdd } from "react-icons/hi";
import { IoLogIn } from "react-icons/io5";

const pages = [
    { name: 'HOME', path: '/' },
    { name: 'PATIENTS', path: '/patients' },
    { name: 'DOCTORS', path: '/doctors' },
    { name: 'PHARMACY', path: '/pharmacy' },
    { name: 'STAFF', path: '/staff' }
];

function Header() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <div>
            <AppBar position="static" sx={{ backgroundColor: '#fff' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <img src={Logo} alt="Logo" className="h-14 mr-11" />
                        
                        {/* Menu icon for mobile view */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="black"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                keepMounted
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{ display: { xs: 'block', md: 'none' } }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                        <Link to={page.path} style={{ textDecoration: 'none', color: '#000' }}>
                                            <Typography sx={{ textAlign: 'center' }}>{page.name}</Typography>
                                        </Link>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        {/* Desktop view menu */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Link key={page.name} to={page.path} style={{ textDecoration: 'none' }}>
                                    <Button
                                        onClick={handleCloseNavMenu}
                                        sx={{ my: 2, color: 'black', display: 'block', fontWeight: '500' }}
                                    >
                                        {page.name}
                                    </Button>
                                </Link>
                            ))}
                        </Box>

                        {/* Register and Log In buttons */}
                        <Box sx={{ flexGrow: 0, display: 'flex', gap: 2 }}>
                            <Link to="/register" className='no-underline'>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    sx={{
                                        height: '30px',
                                        backgroundColor: '#97CE4F',
                                        color: '#000',
                                        fontWeight: '500',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        padding: { xs: '4px 8px', md: 'auto' },
                                    }}
                                >
                                    <HiUserAdd style={{ fontSize: '18px' }} />
                                    <Typography sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        Register
                                    </Typography>
                                </Button>
                            </Link>

                            <Link to="/signin" className='no-underline'>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    sx={{
                                        height: '30px',
                                        backgroundColor: '#97CE4F',
                                        color: '#000',
                                        fontWeight: '500',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        padding: { xs: '4px 8px', md: 'auto' },
                                    }}
                                >
                                    <IoLogIn style={{ fontSize: '18px' }} />
                                    <Typography sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        Log In
                                    </Typography>
                                </Button>
                            </Link>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <div className="flex justify-between items-start sm:items-center flex-col px-4 sm:px-6 md:px-9 h-[105px] sm:h-[95px] md:h-[65px] sm:flex-row bg-[#97CE4F]">
                <h1 className="text-fontDark font-bold text-3xl sm:text-4xl md:text-5xl uppercase">
                    Amarasingha <span className="text-lightColor">medicare</span>
                </h1>

                <h1 className="text-fontDark font-bold text-2xl sm:text-4xl md:text-5xl uppercase flex gap-x-3">
                    <FaPhone /> 036 2259 545
                </h1>
            </div>
        </div>
    );
}

export default Header;

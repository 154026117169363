import React, { useState, useRef } from 'react';

//components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Message from '../../components/Message';

//images
import profilebg from '../../img/doctorsProfileBg.png';
import profile from '../../img/doctorsPageIcon.png';

//icons
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { AiOutlineLogout } from "react-icons/ai";
import { MdFormatAlignJustify, MdOutlineMessage, MdEventNote, MdOutlineAccountCircle, MdAutoAwesomeMosaic } from "react-icons/md";
import { TfiReceipt } from "react-icons/tfi";

const Staff = () => {

  const [activeMenu, setActiveMenu] = useState('dashboard'); 
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const rightSideRef = useRef(null);

  const staffDetails = {
    name: 'Admin',
    field: 'Main',
  };

  // Scroll to the top of the right side when the menu is clicked
  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    setIsMobileMenuOpen(false);
    if (rightSideRef.current) {
      rightSideRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  
  return (
      <div className="bg-gradient-to-l from-[#98DED9]  to-[#fdfdff] to-60%">
        <Header />
        <div className="grid w-full h-auto grid-cols-1 mt-2 md:grid-cols-7">
          {/* left side */}
          <div className="col-span-1 md:col-span-2 border-2 border-[#98DED9] m-2 md:ml-3 md:mt-3 h-auto">
            {/* profile */}
            <div className="bg-[#F1EFFC] w-full h-auto border-b-2 border-b-[#98DED9]">
              <img src={profilebg} alt="profile_background" className="w-full h-[229px] object-cover" />
              <div className="pb-2">
                <img src={profile} alt="Profile_image" className="object-cover w-32 h-32 mx-auto -mt-16 rounded-full shadow-lg" />
                <h2 className="uppercase text-center mt-2 font-bold text-[25px] text-[#161D6F]">{staffDetails.name}</h2>
                <h4 className="uppercase text-center -mt-2 font-bold text-[20px] text-[#7465B9]">{staffDetails.field}</h4>
              </div>
            </div>
            {/* navbar */}
            {/* menu items (Desktop) */}
            <div className="hidden md:block my-12 ml-6 ">
              <ul className="text-[20px] font-bold">

                {[
                  { key: 'dashboard', label: 'Dashboard', icon: <MdFormatAlignJustify className="mr-5 text-2xl" /> },
                  { key: 'appointment', label: 'Appointment', icon: <BsFillJournalBookmarkFill className="mr-5 text-xl" /> },
                  { key: 'scheduleTiming', label: 'Schedule Timing', icon: <MdEventNote className="mr-5 text-2xl" /> },
                  { key: 'messages', label: 'Messages', icon: <MdOutlineMessage className="mr-5 text-2xl" /> },
                  { key: 'invoice', label: 'Invoice', icon: <TfiReceipt className="mr-5 text-2xl" /> },
                  { key: 'profileSettings', label: 'Profile Settings', icon: <MdOutlineAccountCircle className="mr-5 text-2xl" /> },
                  { key: 'logout', label: 'Logout', icon: <AiOutlineLogout className="mr-5 text-2xl" /> },
                ].map((item) => (
                  <li key={item.key} className="my-7">
                    <button
                      onClick={() => handleMenuClick(item.key)}
                      className={`w-full text-left no-underline focus:outline-none hover:text-[#5f44c8] rounded-lg
                        ${activeMenu === item.key ? 'text-[#00FF03] hover:text-[#4ae14c]' : 'text-[#261C53]'}`}
                    >
                      <div className="flex items-center">
                        {item.icon}
                        <span className="sidebar-title">{item.label}</span>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            {/* menu items (Mobile) */}
            <div className="md:hidden my-12 mx-6">
              <a
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className={`flex items-center w-full text-left no-underline focus:outline-none hover:text-[#5f44c8] rounded-lg text-[20px] font-bold transition-all duration-300 ease-in-out
                  ${isMobileMenuOpen ? 'text-[#5f44c8] scale-105 ml-4' : 'text-[#261C53] scale-100 ml-0'}`}
              >
                <MdAutoAwesomeMosaic className="mr-5 text-2xl" />
                <span>MENU</span>
              </a>
              <div
                className={`overflow-hidden transition-all duration-700 ease-in-out
                  ${isMobileMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}
              >
                <ul className="mt-4 text-[20px] font-bold border-y-2 border-[#c4c4c4]">
                  {[
                    { key: 'dashboard', label: 'Dashboard', icon: <MdFormatAlignJustify className="mr-5 text-2xl" /> },
                    { key: 'appointment', label: 'Appointment', icon: <BsFillJournalBookmarkFill className="mr-5 text-xl" /> },
                    { key: 'scheduleTiming', label: 'Schedule Timing', icon: <MdEventNote className="mr-5 text-2xl" /> },
                    { key: 'messages', label: 'Messages', icon: <MdOutlineMessage className="mr-5 text-2xl" /> },
                    { key: 'invoice', label: 'Invoice', icon: <TfiReceipt className="mr-5 text-2xl" /> },
                    { key: 'profileSettings', label: 'Profile Settings', icon: <MdOutlineAccountCircle className="mr-5 text-2xl" /> },
                    { key: 'logout', label: 'Logout', icon: <AiOutlineLogout className="mr-5 text-2xl" /> },
                  ].map((item) => (
                    <li key={item.key} className="my-5">
                      <button
                        onClick={() => handleMenuClick(item.key)}
                        className={`w-full ml-5 text-left no-underline focus:outline-none hover:text-[#5f44c8] rounded-lg transition-colors duration-300 ease-in-out
                          ${activeMenu === item.key ? 'text-[#00FF03]' : 'text-[#261C53]'}`}
                      >
                        <div className="flex items-center">
                          {item.icon}
                          <span className="sidebar-title">{item.label}</span>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* right side */}
          <div ref={rightSideRef} className="h-auto col-span-1 m-2 md:col-span-5 border-1 md:ml-3 md:mt-3">
            {/* components */}
            {activeMenu === 'dashboard' && <div>Welcome to the Dashboard!</div>}
            {activeMenu === 'appointment' && <div>Welcome to the Appointment!</div>}
            {activeMenu === 'scheduleTiming' && <div>Welcome to the Schedule Timing!</div>}
            {activeMenu === 'messages' && <div><Message/></div>}
            {activeMenu === 'invoice' && <div>Welcome to the Invoice!</div>}
            {activeMenu === 'profileSettings' && <div>Welcome to the Profile Settings!</div>}
            {activeMenu === 'logout' && <div>Welcome to the Logout!</div>}
          </div>
        </div>
        <Footer />
      </div>

  );
};

export default Staff;
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Instructions from '../components/InstructionNav';
import Category from '../components/Categories';
import Search from '../components/Search';


const Index = () => {
  const categoryData = [
    { name: 'Physiotherapist', sinname: 'කායික රෝග පිළිබඳ විශේෂඥ', image: require('../assest/images/jish28os.jpg') },
    { name: 'pediatrician', sinname: 'ළමා රෝග පිළිබඳ විශේෂඥ', image: require('../assest/images/ee85a3bb3281dbca051fc70eec2fd896.jpg') },
    { name: 'arthrologist', sinname: 'සන්ධි රෝග පිළිබඳ විශේෂඥ', image: require('../assest/images/2c5a9777d6309cedbf2e95ca574826a8.jpg') },
    { name: 'obstetrician and gynecologist', sinname: 'ප්‍රසව හා නාරිවේද පිළිබඳ විශේෂඥ', image: require('../assest/images/f5b5cbb6eaa475f8c319b47a04c4f46d.jpg') }
  ];

  return (
    <div>
      <Header />
      <Instructions />
      <Search />

      <div className="h-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 w-full">
        {categoryData.map((category, index) => (
          <Category 
            key={index} 
            name={category.name} 
            image={category.image} 
            sinname={category.sinname}
          />
        ))}
      </div>
      
      <Footer />
    </div>
  );
};

export default Index;

import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { FaChevronLeft } from "react-icons/fa6";

//Images
import Background from '../../assest/images/Background.png';
import Formbg from '../../assest/images/bgf.jpg';


const Bookingform = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [formData, setFormData] = useState({
    patientId: '',
    mobileNo: '',
    title: 'Mr.',
    firstName: '',
    lastName: '',
    gender: '',
    ongoingNumber: 'no',
    age: '',
    nic: '',
    dateOfBirth: { day: '', month: '', year: '' }
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('dateOfBirth.')) {
      const field = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        dateOfBirth: { ...prev.dateOfBirth, [field]: value }
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleGenerate = () => {
    console.log('Generating patient details...');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting form...', formData);
  };

  const [showUnregisteredTable, setShowUnregisteredTable] = useState(false);
  const [showRegisteredTable, setShowRegisteredTable] = useState(false);
  const patients = [
    { no: '01', time: '06.00PM', available: false },
    { no: '02', time: '06.10PM', available: false },
    { no: '03', time: '06.20PM', available: true },
    { no: '04', time: '06.30PM', available: true },
    { no: '05', time: '06.40PM', available: true },
    { no: '06', time: '06.50PM', available: false },
    { no: '07', time: '07.00PM', available: false },
    { no: '08', time: '07.10PM', available: true },
    { no: '09', time: '07.20PM', available: true },
    { no: '10', time: '07.30PM', available: true },
  ];


  return (
    <div >
      <Header />
      <div class="min-h-screen w-full h-[1301px] bg-cover bg-center" style={{ backgroundImage: `url(${Background})` }}>
        <main className="container mx-auto px-14 py-8">

          {/* Back Button */}
          <div className="mb-10 ">
          <button
              onClick={() => window.history.back()}
              className="flex items-center font-arial px-2 py-1 -ml-3 rounded transition-colors mb-11 -mt-1 font-semibold bg-[#000080] text-white"
            >
              <FaChevronLeft />
              Back
            </button>
          </div>

          {/* Main Title */}
          <h1 className="text-[40px] text-center font-[Montserrat] text-[#161D6F] mb-12 -mt-16">
            Making Health Care Better Together
          </h1>

          {/* Forms Container */}
          <div className="grid md:grid-cols-2 gap-16">

            {/* --------------------------- Unregistered Patients Form ----------------------------------- */}

            <div className="bg-white absolute w-[565px] h-[1020px] left-[102px] top-[298px] rounded-[2px] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] bg-cover bg-center -mt-2 " style={{ backgroundImage: `url(${Formbg})` }}>
              {/* Form Header */}
              <div className="text-center -mt-22 p-4 border-b-2 border-[#161D6F]">
                <h1 className="text-[30px] font-[Calibri] text-[#161D6F]">FOR UNREGISTERED PATIENTS</h1>
                <p className="text-[#161D6F] font-bold text-[15px] font-[FMEmanee] mt-1">ලියාපදිංචි නොවූ රෝගීන් සදහා</p>
                <h2 className="text-[25px] font-[Calibri] mt-15">Doctor Appointment Request Form</h2>
                <p className="text-[16px] font-[Calibri] text-black font-medium mt-15">
                  Fill the form below and we will get back soon to you for<br />
                  more updates and plan your appointment.
                </p>
              </div>


              {/* Sinhala Instructions */}
              <div className="bg-blue-50 p-4 ">
                <p className="w-full text-[#010003] font-[FMEmanee] text-center font-semibold whitespace-normal text-[11.3px]">
                  ඔබට ලියාපදිංචි නොවී <span className=" text-[15px] font-[Calibri] font-medium text-[#7C0AFF]">Appointment</span> එකක් වෙන් කරගැනීමට අවශ්‍යනම් මෙම පෝරමය පුරවා <span className="text-[15px] font-[Calibri] font-medium text-[#FF0000]">Submit Button</span> එක ඔබන්න. ඔබ අප වෙත අලුතෙන් හෝගී ලියාපදිංචි වීමටත් දැනටමත් ලියාපදිංචි නොවී ආසාදනයක්, මෙම අතර සිදුව ඇත්නම් පුරවා <span className="text-[15px] font-[Calibri] font-medium text-[#FF0000]">Submit & Register Button</span> එක ඔබන්න.
                </p>
              </div>

              {/* Form Content */}
              <div className=" p-6">
                <form onSubmit={handleSubmit} className="space-y-4 z-0">
                  {/* Patient Info */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block mb-1 -mt-4 font-[Calibri] text-[20px] text-[#010003] ">
                        Patient ID
                        <span className="block text-[12px] text-[#000000] font-[FMEmanee] font-semibold">(රෝගී අංකය/හැදුනුම් අංකය.)</span>
                      </label>
                      <input
                        type="text"
                        name="patientId"
                        value={formData.patientId}
                        onChange={handleChange}
                        className="border-[0.5px] w-[208px] rounded-[2px] p-1"
                      />
                    </div>

                    <div>
                      <label className="block mb-1 -mt-4 font-[Calibri] text-[20px]">
                        Patient Mobile No:
                        <span className="block text-[12px] text-[#010003] font-[FMEmanee] font-semibold">(දුරකථන අංකය)</span>
                      </label>
                      <input
                        type="tel"
                        name="mobileNo"
                        placeholder="(000) 000-0000"
                        value={formData.mobileNo}
                        onChange={handleChange}
                        className="border-[0.5px] w-[208px] rounded-[2px] p-1"
                      />
                    </div>

                    {/* Name Fields */}
                    <div className="col-span-2">
                      <label className="block mb-1 -mt-4 font-[Calibri]  text-[20px]">
                        Name
                        <span className="block text-[12px] text-[#010003] font-[FMEmanee] font-semibold">(නම, මුල් නම සහ අග නම විස්තරය් ඇතුලත් කරන්න.)</span>
                      </label>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <select className="font-[Calibri] text-[15px] border-[0.5px] w-[208px] rounded-[2px] p-1">
                            <option>Mr.</option>
                            <option>Mrs.</option>
                            <option>Mast.</option>
                            <option>Miss.</option>
                            <option>Dr.</option>
                            <option>Dr (Ms).</option>
                            <option>Dr (Mrs).</option>
                            <option>Prof.</option>
                            <option>Prof (Ms).</option>
                            <option>Prof (Mrs).</option>
                            <option>Rev.</option>
                            <option>Baby.</option>
                            <option>Sis.</option>
                            <option>Ms.</option>
                            <option>Hon.</option>
                            <option>Rev. Sr.</option>
                            <option>Ven.</option>
                            <option>Rev. Fr.</option>

                          </select>
                          <input
                            type="text"
                            placeholder="First Name"
                            className="border-[0.5px] w-[208px] rounded-[2px] p-1 mt-2 text-[15px]"
                          />
                        </div>
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="border-[0.5px] w-[208px] h-[32px] rounded-[2px] p-1 mt-9 text-[15px] ml-45"
                        />
                      </div>
                    </div>

                    {/* Gender */}
                    <div>
                      <label className="block mb-1 -mt-3 text-[20px] font-[Calibri] ">
                        Gender
                        <span className="block text-[12px] text-[#000000] font-[FMEmanee] font-semibold">(ස්ත්‍රී / පුරුෂ භාවය.)</span>
                      </label>
                      <select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        className="border-[0.5px] w-[208px] rounded-[2px] p-1 "
                      >
                        <option value="">Please Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="none">Not willing to Disclose</option>
                      </select>
                    </div>

                    {/* Ongoing Number */}
                    <div className="w-full">
                      <div className="relative">
                        <div className="relative mt-3 flex items-start gap-4">
                          <div className="absolute left-1/3 transform -translate-x-1/2">
                            <label className="absolute -ml-9 w-[140px] h-[24px] font-[Calibri] font-normal text-[20px] -mt-1 ">Ongoing Number</label>
                            <div className="flex gap-4 mt-2 -ml-6">
                              <label className="flex items-center mt-6 font-[Calibri] text-[16px]">
                                <input
                                  type="radio"
                                  name="ongoing"
                                  value="yes"
                                  className="mr-2" 
                                  class=""
                                  defaultChecked
                                  onChange={() => setShowUnregisteredTable(false)}
                                />
                                Yes
                              </label>
                              <label className="flex items-center mt-6 font-[Calibri] text-[16px]">
                                <input
                                  type="radio"
                                  name="ongoing"
                                  value="no"
                                  className="mr-2 w-[15px] h-[15px] bg-[#D9D9D9] border border-black rounded-full"
                                  onChange={() => setShowUnregisteredTable(true)}
                                />
                                No
                              </label>
                            </div>
                          </div>
                        </div>

                        {showUnregisteredTable && (
                          <div className="absolute top-20 left-7 z-50">
                            <div className="w-[318px] bg-gradient-to-b from-[#A7D3FF] to-[#FFFFFF] p-2 drop-shadow-[-6px_6px_4px_rgba(0,0,0,0.25)]">
                              <table className="w-full border-collapse text-xs">
                                <thead>
                                  <tr className="border-b border-gray-900">
                                    <th className="py-1 px-1 text-center font-medium text-[#7C0AFF] border-r border-black w-1/5">
                                      Patient
                                      <span className="inline-block">No:</span>
                                    </th>
                                    <th className="py-1 px-1 text-center font-medium text-[#7C0AFF] border-r border-black w-1/5">
                                      Time
                                    </th>
                                    <th className="py-1 px-1 text-center font-medium text-[#7C0AFF] border-r border-black w-1/5">
                                      Availability
                                    </th>
                                    <th className="py-1 px-1 w-2/5">
                                      <div className="text-[9px] font-[FMEmanee] text-[#7C0AFF] font-normal text-left whitespace-normal">
                                        දැනට පවතින වේලාවන්‌ අනුව ඔබ කැමති අංකයකට ටික් කරන්න.
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {patients.map((patient, index) => (
                                    <tr key={patient.no} className={index !== patients.length - 1 ? "border-none" : ""}>
                                      <td className="py-1 px-2 text-xs border-r border-gray-900 text-center w-1/5">
                                        {patient.no}
                                      </td>
                                      <td className="py-1 px-2 text-xs border-r border-gray-900 text-center w-1/5">
                                        {patient.time}
                                      </td>
                                      <td className="py-1 px-2 text-xs border-r border-gray-900 text-center w-1/5">
                                        {patient.available ? 'Yes' : 'No'}
                                      </td>
                                      <td className="py-1 px-2">
                                        <div className="flex justify-center">
                                          <div className="h-4 border border-black rounded-sm flex items-center justify-center w-4">
                                            {patient.available && (
                                              <span className="text-blue-600 leading-none"><b>✓</b></span>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>


                    {/* Age  */}
                    <div className="flex justify-between items-end">
                      <div>
                        <label className="block mb-1 text-[20px] font-[Calibri] -mt-4  "> Age
                          <span className="block text-[12px] text-[#000000] font-[FMEmanee] font-semibold mb-2">(වයස.)</span>
                        </label>
                        <input
                          type="text"
                          className=" -mt-9 border-[0.5px] w-[104px] rounded-[2px] p-1"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-center">
                    <button
                      type="submit"
                      className="bg-[#97CE4F] -mt-12 text-[19px] font-[Calibri] font-medium text-black px-4 py-1.5 rounded hover:bg-[#87b43f] transition-colors "
                    >
                      Submit
                    </button>
                  </div>



                  <div className="-ml-4 -mr-4 leading-[16px] tracking-[0.7px] ">
                    <p className="w-full font-[FMEmanee] text-[10px] text-black font-extrabold text-left whitespace-normal">
                      ඔබ අපගේ රෝගී දත්ත ගබඩාව වෙත තමාගෙ වෛද්‍ය වාඊතා <span className="text-[13px] font-[Calibri] font-normal "> (Medical Report) </span> ඵකතු කර ජාල ගත කිරීමට කැමති නම්‌ පමණක්‌
                      <span className="text-[13px] font-[Calibri] font-normal "> NIC or Date of Birth </span>
                      එකතු කරන්න මෙවිට ඔබගේ සියලු වෛද්‍ය වාර්තා චායාරූප ආකාරයට ඔබට යොමු කර ගත හැකි අතර එම තොරතුරු වෛද්‍යවරයා වෙත<span className="text-[13px] font-[Calibri] font-normal "> Online </span>ආකාරයට පෙන්විය හැකිය.
                    </p>
                  </div>


                  {/* NIC */}
                  <div className="grid grid-cols-2 gap-5">
                    <div>
                      <label className="block mb-1 text-[20px] font-[Calibri] -mt-2 ">
                        NIC
                        <span className="block text-[12px] text-[#000000] font-[FMEmanee] font-semibold">(ජාතික හැදුනුම්පත් අංකය.)</span>
                      </label>
                      <input
                        type="text"
                        name="nic"
                        value={formData.nic}
                        onChange={handleChange}
                        className="border-[0.5px] w-[208px] rounded-[2px] p-1 "
                      />
                    </div>

                    {/* Date of Birth */}
                    <div >
                      <label className="block mb-1 text-[20px] font-[Calibri] -mt-2 ">
                        Date of Birth
                        <span className="block text-[12px] text-[#000000] font-[FMEmanee] font-semibold">(උපන් දිනය.)</span>
                      </label>
                      <div className="grid grid-cols-3 gap-2">
                        <input
                          placeholder="DD"
                          name="dateOfBirth.day"
                          value={formData.dateOfBirth.day}
                          onChange={handleChange}
                          className=" text-center absolute w-[61px] h-[34px]  border-[0.5px] rounded-[2px] p-2 "
                        />
                        <input
                          placeholder="MM"
                          name="dateOfBirth.month"
                          value={formData.dateOfBirth.month}
                          onChange={handleChange}
                          className="text-center absolute w-[61px] h-[34px] left-[363px] top-[883px] border-[0.5px] rounded-[2px] "
                        />
                        <input
                          placeholder="YY"
                          name="dateOfBirth.year"
                          value={formData.dateOfBirth.year}
                          onChange={handleChange}
                          className="text-center absolute w-[61px] h-[34px] left-[435px] top-[883px] border-[0.5px] rounded-[2px]"
                        />
                      </div>
                    </div>
                  </div>


                  {/* Submit Buttons */}
                  <div className="flex flex-col items-center gap-4">
                    <button
                      type="button"
                      onClick={() => setIsRegistered(true)}
                      className="bg-[#97CE4F] text-[19px] font-[Calibri] font-medium text-black px-3 py-2 rounded hover:bg-[#87b43f] transition-colors mt-2 "
                    >
                      Submit & Register
                    </button>
                  </div>

                </form>
              </div>
            </div>


            {/* -------------------------- Registered Patients Form --------------------------------- */}

            <div className="bg-white absolute -mt-1 w-[565px] h-[790px] left-[779px] top-[295px] rounded-[2px] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] bg-cover bg-center min-h-screen flex flex-col" style={{ backgroundImage: `url(${Formbg})` }}>
              {/* Form Header */}
              <div className="text-center -mt-22 p-4 border-b-2 border-[#161D6F]">
                <h1 className="text-[30px] font-[Calibri] text-[#161D6F]">FOR ALREADY REGISTERED PATIENTS</h1>
                <p className="text-[#161D6F] font-bold text-[15px] font-[FMEmanee] mt-1">දැනටමත් ලියාපදිංචි වූ අයවී රෝගීන් සදහා</p>
                <h2 className="text-[25px] font-[Calibri] mt-15">Doctor Appointment Request Form</h2>
                <p className="text-[16px] font-[Calibri] text-black font-medium mt-15">
                  Fill the form below and we will get back soon to you for<br />
                  more updates and plan your appointment.
                </p>
              </div>

              {/* Form Content */}
              <div className="p-6">
                <form className=" space-y-4">
                  {/* Patient Info */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block mb-1 -mt-2 font-[Calibri] text-[20px] text-[#010003]">
                        Patient ID
                        <span className="block text-[12px] text-[#000000] font-[FMEmanee] font-semibold">(රෝගී හැදුනුම්පත් අංකය.)</span>
                      </label>
                      <input
                        type="text"
                        className="border-[0.5px] w-[208px] rounded-[2px] p-1"
                      />
                    </div>

                    <div>
                      <label className="block mb-1 -mt-2 font-[Calibri] text-[20px]">
                        Patient Mobile No:
                        <span className="block text-[12px] text-[#010003] font-[FMEmanee] font-semibold">(දුරකථන අංකය)</span>
                      </label>
                      <input
                        type="tel"
                        placeholder="(000) 000-0000"
                        className="border-[0.5px] w-[208px] rounded-[2px] p-1"
                      />
                    </div>
                  </div>

                  {/* Instructions with better spacing */}
                  <div className="w-full text-[#010003] font-[FMEmanee] text-center font-semibold whitespace-normal text-[11.3px]">
                    <p>
                      ඔබ අප වෙබ් අඩවියේ රෝගී ලියාපදිංචියේ දැනටමත්‌ ලියාපදිංචි වී ඇත්නමි,
                      <span className="font-semibold text-[#7C0AFF]"> රෝගී හැඳුනුම්පත්‌ අංකය</span> සහ <span className="font-semibold text-[#7C0AFF]"> රෝගී අංකය </span>
                      යොදා <span className="text-[15px] font-[Calibri] font-medium text-[#FF0000]">Generate</span> බොත්තම ඔබන්න, එවිට නම,වයස
                      ආදිය <span className="text-[15px] font-[Calibri] font-medium text-[#FF0000]">Generate</span> වේ. එම තොරතුරැ නිවැරැදි නම්
                      <span className=" text-[15px] font-[Calibri] font-medium text-[#7C0AFF]"> Submit Button </span> එක යෙදීමෙන්‌ හමුවීම තහවුරු කර ගන්න.
                    </p>
                  </div>

                  {/* Generate Button */}
                  <div className="flex justify-center mb-6">
                    <button
                      type="button"
                      onClick={handleGenerate}
                      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#7A29C6'} // Darker purple on hover
                      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#7C0AFF'}
                      className="px-3 py-1 font-[Calibri] text-[20px] rounded transition-colors"
                      style={{ backgroundColor: '#7C0AFF', color: '#FFFFFF' }} // Purple background and white text
                    >
                      Generate
                    </button>
                  </div>


                  {/* Name Section */}
                  <div>
                    <label className="block mb-1 -mt-4 font-[Calibri] text-[20px]">Name</label>
                    <div className="grid grid-cols-2 gap-4">
                      <select
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        className="font-[Calibri] text-[15px] border-[0.5px] w-[208px] h-[35px] rounded-[2px] p-1"
                      >
                        <option>Mr.</option>
                        <option>Mrs.</option>
                        <option>Mast.</option>
                        <option>Miss.</option>
                        <option>Dr.</option>
                        <option>Dr (Ms).</option>
                        <option>Dr (Mrs).</option>
                        <option>Prof.</option>
                        <option>Prof (Ms).</option>
                        <option>Prof (Mrs).</option>
                        <option>Rev.</option>
                        <option>Baby.</option>
                        <option>Sis.</option>
                        <option>Ms.</option>
                        <option>Hon.</option>
                        <option>Rev. Sr.</option>
                        <option>Ven.</option>
                        <option>Rev. Fr.</option>
                      </select>
                      <div className="col-span-2 grid grid-cols-2 gap-2">
                        <input
                          name="firstName"
                          placeholder="First Name"
                          value={formData.firstName}
                          onChange={handleChange}
                          className="border-[0.5px] w-[208px] h-[35px] rounded-[2px] p-1 -mt-2 text-[15px]"
                        />
                        <input
                          name="lastName"
                          placeholder="Last Name"
                          value={formData.lastName}
                          onChange={handleChange}
                          className="border-[0.5px] w-[208px] h-[35px] rounded-[2px] p-1 -mt-2 text-[15px] ml-2"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Gender */}
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <label className="block mb-1 mt-2 text-[20px] font-[Calibri]">Gender</label>
                      <select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        className="border-[0.5px] w-[208px] h-[35px] rounded-[2px] p-1"
                      >
                        <option value="">Please Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="none">Not willing to Disclose</option>
                      </select>
                    </div>

                    {/*  Ongoing Number */}
                    <div className="w-full">
                      <div className="relative">
                        <div className="relative mt-3 flex items-start gap-4">
                          <div className="absolute left-1/3 transform -translate-x-1/2 mt-2 -ml-1">
                            <label className="absolute w-[140px] h-[24px] -ml-6 font-[Calibri] font-normal text-[20px] -mt-2 ">Ongoing Number</label>
                            <div className="flex gap-4 mt-6 -ml-1">
                              <label className="flex items-center font-[Calibri] text-[16px]">
                                <input
                                  type="radio"
                                  name="ongoing"
                                  value="yes"
                                  className="mr-2"
                                  defaultChecked
                                  onChange={() => setShowRegisteredTable(false)}
                                />
                                Yes
                              </label>
                              <label className="flex items-center font-[Calibri] text-[16px]">
                                <input
                                  type="radio"
                                  name="ongoing"
                                  value="no"
                                  className="mr-2"
                                  onChange={() => setShowRegisteredTable(true)}
                                />
                                No
                              </label>
                            </div>
                          </div>
                        </div>

                        {showRegisteredTable && (
                          <div className="absolute top-20 left-7 z-50">
                          <div className="w-[318px] bg-gradient-to-b from-[#A7D3FF] to-[#FFFFFF] p-2 drop-shadow-[-6px_6px_4px_rgba(0,0,0,0.25)]">
                            <table className="w-full border-collapse text-xs">
                              <thead>
                                <tr className="border-b border-gray-900">
                                  <th className="py-1 px-1 text-center font-medium text-[#7C0AFF] border-r border-black w-1/5">
                                    Patient
                                    <span className="inline-block">No:</span>
                                  </th>
                                  <th className="py-1 px-1 text-center font-medium text-[#7C0AFF] border-r border-black w-1/5">
                                    Time
                                  </th>
                                  <th className="py-1 px-1 text-center font-medium text-[#7C0AFF] border-r border-black w-1/5">
                                    Availability
                                  </th>
                                  <th className="py-1 px-1 w-2/5">
                                    <div className="text-[9px] font-[FMEmanee] text-[#7C0AFF] font-normal text-left whitespace-normal">
                                      දැනට පවතින වේලාවන්‌ අනුව ඔබ කැමති අංකයකට ටික් කරන්න.
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {patients.map((patient, index) => (
                                  <tr key={patient.no} className={index !== patients.length - 1 ? "border-none" : ""}>
                                    <td className="py-1 px-2 text-xs border-r border-gray-900 text-center w-1/5">
                                      {patient.no}
                                    </td>
                                    <td className="py-1 px-2 text-xs border-r border-gray-900 text-center w-1/5">
                                      {patient.time}
                                    </td>
                                    <td className="py-1 px-2 text-xs border-r border-gray-900 text-center w-1/5">
                                      {patient.available ? 'Yes' : 'No'}
                                    </td>
                                    <td className="py-1 px-2">
                                      <div className="flex justify-center">
                                        <div className="h-4 border border-black rounded-sm flex items-center justify-center w-4">
                                          {patient.available && (
                                            <span className="text-blue-600 leading-none"><b>✓</b></span>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>



                  {/* Age and Submit Button */}
                  <div className="flex justify-between items-end">
                    <div>
                      <label className="block mb-1 text-[20px] font-[Calibri] mt-2 ">Age</label>
                      <input
                        type="text"
                        className="-mt-9 border-[0.5px] w-[104px] rounded-[2px] p-1"
                      />
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className=" flex justify-center mb-6">
                    <button
                      type="button"
                      className="bg-[#97CE4F] -mt-12 text-[19px] font-[Calibri] font-medium text-black px-3 py-1 rounded hover:bg-[#87b43f] transition-colors "
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </main>
      </div>

      <Footer />
    </div>
  );
};

export default Bookingform;
import React, { useState } from 'react'
import bgimage from '../img/patientBg.png';
import image1 from '../img/Google.png';
import image2 from '../img/Apple.png';
import image3 from '../img/Facebook.png';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaRegEyeSlash } from "react-icons/fa6";
 
export default function PatientLog() {
  const style = {
    backgroundImage: `url(${bgimage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
   /* height:'100vh',*/
    width: '100%',
  };
  const [show, setshow] = useState(false)
  console.log(show);

  const [email, setEmail] = useState('');
  
  return (
    <div  style={style} >
    <div className='xl:h-full h-[900px]'>
      <br />
      <br />
      <Link to="/signin" className='no-underline'>
      <button className='bg-[#00008B] w-[100px] font-bold xl:ml-[100px] ml-[20px] rounded text-lightColor text-center shadow-custom-dark hover:bg-[#354da2] flex p-2' >
      <IoIosArrowBack className='mt-[5px] ml-[12px] font-bold'/>
       Back
       </button>
      </Link>
      <div className='w-[408px] xl:ml-[1030px] ml-[30px] md:ml-[300px]'>
      <h1 className='font-bold text-[#261C53]  pt-[10px] text-[46px]'>Welcome to Our<br/> Patient Portal!</h1>
      <div className='mt-10'>
        
      <form action="">
      <div className='flex'>
      <input type='email' placeholder='Enter Email or Phone Number' 
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
      className='w-[360px] mt-[10px] p-2 placeholder-[#cc8bf5] placeholder:text-sm border-[2px] border-[#cc8bf5] purple rounded-md pl-5 py-1.5'/> 
       <IoMdCloseCircleOutline className='absolute ml-[320px] mt-5 text-[#cc8bf5] cursor-pointer'/>
       {email && (
      <IoMdCloseCircleOutline className='absolute ml-[320px] mt-5 text-[#cc8bf5] cursor-pointer hover:text-black'
      onClick={() => setEmail('')}/>)}
      </div>
      <p className='pl-5 pr-5 text-sm font-extrabold text-[#7465B9]'>ඔබගේ ඊමේල් හෝ දුරකථන අංකය ඇතුලත් කරන්න</p>
      
      <div className='flex'>
      <input type={show ? 'text':'password'} placeholder='Enter your Password' required
      className='w-[360px] mt-[20px] placeholder-[#cc8bf5] placeholder:text-sm border-[2px] border-[#cc8bf5] rounded-md pl-5 py-1.5'/>
      <FaRegEyeSlash className='absolute ml-[320px] mt-8 text-[#cc8bf5] cursor-pointer hover:text-black' onClick={()=>setshow(!show)}/>
      </div>
      <p className='pl-5 pr-5 text-sm font-extrabold text-[#7465B9]'>මුරපදය ඇතුලත් කරන්න</p>
      <br/>

      <input type="checkbox" />
      <span className='pl-2 text-sm font-medium text-[#261C53]'>Remember me</span>
      <span className='pl-[130px] text-sm text-[#261C53] font-medium hover:underline'>Forgot Passwort?</span>
      <br />
      <input type="button" value="Log In" className='p-2.5 rounded mt-2 bg-[#7465B9] w-[350px] hover:bg-[#8050ac] ml-2 text-lightColor text-xl font-bold'/>
    
      </form>  
      </div>
      
      
      <div class="flex items-center justify-center mt-10 mr-[60px] ml-[10px] ">
          <div class=" border-t border-[#7465B8] w-[102px] px-[10px]"></div>
          <span class=" text-[#261C53] ml-2 mr-2">Or continue with</span>
          <div class="border-t border-[#7465B8] w-[102px]"></div>
      </div>
      
        <div className='flex items-center pr-2 mt-8 ml-[5px] mr-2'>
          <img src={image1} alt="Google" className='w-[110px] mr-2'/>
          <img src={image2} alt="Mac" className='w-[110px]'/>
          <img src={image3} alt="Facebook" className='w-[110px] ml-2'/>
        </div>
        
      </div>
      </div>
    </div>
  )
}

import React from 'react';
import { Link } from 'react-router-dom';

const Category = ({ name, sinname, image }) => {
    return (
        <div className='h-96 ml-3 mb-3 mr-3 border-2 rounded-xl border-solid border-fontDark overflow-hidden relative'>
            <div className='bg-green h-fit w-fit absolute flex justify-center items-center rounded-lg z-20'>
                <h1 className='p-2 text-sm sm:text-lg md:text-xl uppercase font-bold'>{name}</h1>
            </div>

            <div className='absolute inset-0 bg-overlay opacity-100 z-10'></div>

            <div className='h-full w-full flex justify-center items-center absolute z-30 flex-col mt-1 sm:mt-3 md:mt-4'>
                <h1 className='text-sm sm:text-lg md:text-xl font-bold'>({sinname})</h1>

                <div className='h-56 w-11/12 mt-2 overflow-auto'>
                <table className="table-fixed h-full w-full overflow-auto">
                    <thead>
                        <tr>
                            <th className="w-1/4"></th>
                            <th className="w-1/5"></th>
                            <th className="w-1/5 text-xs sm:text-sm md:text-base">Patient No</th>
                            <th className="w-1/4 text-xs sm:text-sm md:text-base">Make Appointment</th>
                            <th className="w-1/7 text-xs sm:text-sm md:text-base">OPN</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="w-1/4 font-bold text-xs sm:text-sm md:text-base">DR. Ajith Kariyawasam</td>
                            <td className="w-1/5 text-xs"><h6 className='bg-[#5D0E41] w-fit rounded-[10px] p-1 text-xs text-[#fff] cursor-pointer'>Patient List</h6></td>
                            <td className="flex justify-center items-center align-middle w-full text-xs sm:text-sm md:text-base">05</td>
                            <td className="w-1/4"><Link to="/bookingform" className='no-underline flex justify-center align-middle'><h6 className='bg-[#0B2F9F] w-fit text-[#fff] p-1 rounded-[5px] cursor-pointer text-xs sm:text-sm md:text-base'>2024.09.28</h6></Link></td>
                            <td className="flex justify-center align-middle w-full text-xs sm:text-sm md:text-base p-1">00</td>
                        </tr>
                        <tr className="mb-10">
                            <td class="w-1/4 font-bold text-xs sm:text-sm md:text-base">DR. Ajith Kariyawasam</td>
                            <td className="w-1/5 text-xs"><h6 className='bg-[#5D0E41] w-fit rounded-[10px] p-1 text-xs text-[#fff] cursor-pointer'>Patient List</h6></td>
                            <td className="flex justify-center align-middle w-full text-xs sm:text-sm md:text-base p-1">05</td>
                            <td className="w-1/4"><Link to="/bookingform" className='no-underline flex justify-center align-middle'><h6 className='bg-[#0B2F9F] w-fit text-[#fff] p-1 rounded-[5px] cursor-pointer text-xs sm:text-sm md:text-base'>2024.09.28</h6></Link></td>
                            <td className="flex justify-center align-middle w-full text-xs sm:text-sm md:text-base p-1">00</td>
                        </tr>
                        <tr>
                            <td className="w-1/4 font-bold text-xs sm:text-sm md:text-base">DR. Ajith Kariyawasam</td>
                            <td className="w-1/5 text-xs"><h6 className='bg-[#5D0E41] w-fit rounded-[10px] p-1 text-xs text-[#fff] cursor-pointer'>Patient List</h6></td>
                            <td className="flex justify-center align-middle w-full text-xs sm:text-sm md:text-base p-1">05</td>
                            <td className="w-1/4"><Link to="/bookingform" className='no-underline flex justify-center align-middle'><h6 className='bg-[#0B2F9F] w-fit text-[#fff] p-1 rounded-[5px] cursor-pointer text-xs sm:text-sm md:text-base'>2024.09.28</h6></Link></td>
                            <td className="flex justify-center align-middle w-full text-xs sm:text-sm md:text-base p-1">00</td>
                        </tr>
                        <tr>
                            <td className="w-1/4 font-bold text-xs sm:text-sm md:text-base">DR. Ajith Kariyawasam</td>
                            <td className="w-1/5 text-xs"><h6 className='bg-[#5D0E41] w-fit rounded-[10px] p-1 text-xs text-[#fff] cursor-pointer'>Patient List</h6></td>
                            <td className="flex justify-center align-middle w-full text-xs sm:text-sm md:text-base p-1">05</td>
                            <td className="w-1/4"><Link to="/bookingform" className='no-underline flex justify-center align-middle'><h6 className='bg-[#0B2F9F] w-fit text-[#fff] p-1 rounded-[5px] cursor-pointer text-xs sm:text-sm md:text-base'>2024.09.28</h6></Link></td>
                            <td className="flex justify-center align-middle w-full text-xs sm:text-sm md:text-base p-1">00</td>
                        </tr>
                    </tbody>
                </table>

                </div>
            </div>

            <div className='h-full'>
                <img className='w-full h-full object-cover' src={image} alt={image} />
            </div>
        </div>
    );
};

export default Category;

import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import GoogleIcon from '../../img/Google.png';
import AppleIcon from '../../img/Apple.png';
import FacebookIcon from '../../img/Facebook.png';
import doctorImage from '../../img/doctor.jpg';  // Import the image directly
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fontsource/montserrat/700.css';

const DoctorLogin = () => {
  return (
    <div className="flex flex-col min-h-screen relative overflow-hidden">
      
      {/* Background Image with Full Screen Cover */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${doctorImage})`,  // Use the imported image
        }}
      ></div>

      {/* Gradient Overlay on Background */}
      <div className="absolute inset-0 bg-gradient-to-r from-[#98DED9] via-[#EEECFC] to-[#EEECFC] opacity-60 z-10"></div>

      {/* Header Component */}
      <div className="relative z-20">
        <Header />
      </div>

      {/* Back Button with Icon */}
      <button
        onClick={() => window.history.back()}
        className="absolute top-[15%] left-5 px-3 py-2 bg-[#161D6F] text-white rounded flex items-center text-m z-30 "
      >
        <i className="fas fa-chevron-left text-white text-s mr-1"></i>
        Back
      </button>

      {/* Main Content Section */}
      <div className="flex justify-end items-center flex-grow gap-7 relative z-20">
        
        {/* Login Form Container */}
        <div className="p-5 rounded-lg max-w-[500px] w-full mr-10 bg-opacity-90">
          
          {/* Welcome Message */}
          <h1 style={{ fontFamily: 'Montserrat, sans-serif' }} className="text-left mb-3 text-[#261C53] font-bold text-5xl">
            Welcome, Doctor!
          </h1>
          
          {/* Informational Paragraph */}
          <p className="text-center mb-3 text-[#261C53]">
            Please sign in to access your dashboard. Here you can manage appointments, view patient records, and access important medical resources. Your commitment to healthcare excellence starts with a click.
          </p>

          {/* Login Form */}
          <form>
            
            {/* Email or Phone Input Field */}
            <div className="relative mb-3">
              <input
                type="text"
                placeholder="Enter Email Or Phone Number"
                className="w-full px-3 py-3 rounded border border-[#7465B9] placeholder-[#7465B9]"
              />
              <i className="fa-regular fa-circle-xmark absolute right-3 top-1/2 transform -translate-y-1/2 text-[#7465B9] cursor-pointer"></i>
            </div>

            {/* Password Input Field */}
            <div className="relative mb-3">
              <input
                type="password"
                placeholder="........."
                className="w-full px-4 py-3 rounded border border-[#7465B9] placeholder-[#7465B9]"
              />
              <i className="fa-regular fa-eye-slash absolute right-3 top-1/2 transform -translate-y-1/2 text-[#7465B9] cursor-pointer"></i>
            </div>

            {/* Department Input Field */}
            <div className="relative mb-3">
              <input
                type="text"
                placeholder="Department"
                className="w-full px-3 py-3 rounded border border-[#7465B9] placeholder-[#7465B9]"
              />
              <i className="fa-regular fa-building absolute right-3 top-1/2 transform -translate-y-1/2 text-[#7465B9] cursor-pointer"></i>
            </div>

            {/* Remember Me and Forgot Password Links */}
            <div className="flex justify-between items-center mb-3">
              <label className="flex items-center gap-2">
                <input type="checkbox" className="mr-2" />
                Remember me
              </label>
              <a href="#" className="text-[#261C53] no-underline">Forgot Password?</a>
            </div>
            
            {/* Login Button */}
            <button
              type="submit"
              style={{ fontFamily: 'Calibri, sans-serif' }}
              className="w-full py-2.5 bg-[#7465B9] rounded text-white text-lg font-bold"
            >
              Login
            </button>

          </form>

          {/* Divider for Alternative Sign-In Options */}
          <div className="mt-8 text-center">
            <div className="flex items-center gap-3">
              <span className="flex-1 border-t border-[#7465B9]"></span>
              <p className="text-[#2B2768]">Or continue with</p>
              <span className="flex-1 border-t border-[#7465B9]"></span>
            </div>
            
            {/* Social Media Sign-In Icons */}
            <div className="flex justify-center gap-8 mt-5">
              <img src={GoogleIcon} alt="Google" className="w-22 h-15 cursor-pointer" />
              <img src={AppleIcon} alt="Apple" className="w-22 h-15 cursor-pointer" />
              <img src={FacebookIcon} alt="Facebook" className="w-22 h-15 cursor-pointer" />
            </div>
          </div>
        </div>
      </div>

      {/* Footer Component */}
      <div className="relative z-20 mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default DoctorLogin;

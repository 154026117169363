import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { IoIosSearch } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa6";

//Images
import PharmacyBg from '../assest/images/PharmacyBg.png';
import Thermometer from '../assest/images/thermo.png';
import Dettol from '../assest/images/dettol.png';
import Stethoscope from '../assest/images/stethoscope.png';
import FingerClip from '../assest/images/oxi.png';
import Pills from '../assest/images/pills.png';
import Plaster from '../assest/images/plaster.png';
import Minipills from '../assest/images/mini.png';
import Siddhalepa from '../assest/images/siddhalepa.png';
import Plate from '../assest/images/plate.png';

const ProductCard = ({ id, image, title, description, price, rating, reviews, sold }) => {
  const navigate = useNavigate();

  const handleImageClick = () => {
    navigate(`/product/${id}`);
  };

  const StarRating = ({ rating, reviews, sold }) => {
    return (
      <div className="flex items-center gap-1 text-sm">
        <div className="flex">
          {[...Array(5)].map((_, i) => (
            <svg key={i} viewBox="0 0 24 24" className="w-5 h-4">
              <path
                fill="#faef1e"
                stroke="#000000"
                strokeWidth="0.85"
                d="M12 2l2.4 7.4h7.6l-6.2 4.5 2.4 7.4-6.2-4.5-6.2 4.5 2.4-7.4-6.2-4.5h7.6z"
              />
            </svg>
          ))}
        </div>
        <span className="font-[Arial] text-black text-sm">
          {rating} | {reviews} Reviews | {sold} Sold
        </span>
      </div>
    );
  };

  const ActionButton = ({ children, bgColor, hoverColor }) => (
    <button className={`w-8 h-8 ${bgColor} ${hoverColor} rounded-full flex items-center justify-center transition-colors`}>
      {children}
    </button>
  );

  return (
    <div className="w-full max-w-sm mt-4">
      {/* White box containing only the image and action buttons */}
      <div className="bg-white shadow-sm p-4 relative mb-4">
        <div className="relative flex justify-center">
          <img
            src={image}
            alt={title}
            className="w-full h-auto object-cover aspect-[1.62] transition-transform duration-300 hover:scale-105 hover:brightness-100 hover:opacity-190"
            onClick={handleImageClick}
          />
          <div className="absolute -top-1 right-2 flex flex-col gap-1">
            <ActionButton bgColor="bg-rose-100 shadow-[0px_2px_2px_rgba(0,0,0,0.25)] w-[33.17px] h-[33.17px]"
              hoverColor="hover:bg-rose-200">
              <svg viewBox="0 0 24 24" className="w-4 h-4">
                <path
                  fill="none"
                  stroke="#EF4444"
                  strokeWidth="2"
                  d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                />
              </svg>
            </ActionButton>
            <ActionButton bgColor="bg-sky-100 shadow-[0px_2px_2px_rgba(0,0,0,0.25)] w-[33.17px] h-[33.17px]"
              hoverColor="hover:bg-sky-200">
              <svg viewBox="0 0 24 24" className="w-4 h-4">
                <path
                  fill="#3B82F6"
                  d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                />
              </svg>
            </ActionButton>
            <ActionButton bgColor="bg-[#FFFEC3] shadow-[0px_2px_2px_rgba(0,0,0,0.25)] w-[33.17px] h-[33.17px]"
              hoverColor="hover:bg-amber-100">
              <svg viewBox="0 0 24 24" className="w-4 h-4">
                <path
                  fill="#EAB308"
                  d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                />
              </svg>
            </ActionButton>
          </div>
        </div>
      </div>

      {/* Product details outside the white box */}
      <div className="space-y-2 px-2">
        <h2 className="font-[Arial] font-normal text-[18px] text-black ">{title}</h2>
        <p className=" font-[Arial] text-[15px] font-normal text-black">{description}</p>
        <p className="text-sm font-[Arial] font-normal">{price} LKR</p>
        <StarRating rating={rating} reviews={reviews} sold={sold} />
      </div>
    </div>
  );
};

export const products = [
  {
    id: 1,
    image: Thermometer,
    title: "NEW Digital LCD Heating Oral Thermometer",
    description: "Measurement Electronic Clinical Thermometer",
    price: "690.00",
    rating: "5.0",
    reviews: "60",
    sold: "97"
  },
  {
    id: 2,
    image: Dettol,
    title: "Dettol Antiseptic Liquid for First Aid",
    description: "Surface Disinfection and Personal Hygiene, 1 Liter",
    price: "700.00",
    rating: "5.0",
    reviews: "60",
    sold: "97"
  },
  {
    id: 3,
    image: Stethoscope,
    title: "Stethoscope Multifunctional Double Tube",
    description: "Professional Doctor Stethoscope",
    price: "1500.00",
    rating: "5.0",
    reviews: "60",
    sold: "97"
  },
  {
    id: 4,
    image: FingerClip,
    title: "Finger Clip Oximeter Home Use Led Oximeter",
    description: "Home Use Meter Portable Visual Finger Clip Oximeter",
    price: "2900.00",
    rating: "5.0",
    reviews: "60",
    sold: "97"
  },
  {
    id: 5,
    image: Pills,
    title: "Medicine Pills",
    description: "Generic Medicine Pills",
    price: "250.00",
    rating: "5.0",
    reviews: "60",
    sold: "97"
  },
  {
    id: 6,
    image: Plaster,
    title: "Waterproof Breathable Wound Plaster",
    description: "First Aid Strips Dressing Patch Adhesive Bandages",
    price: "250.00",
    rating: "5.0",
    reviews: "60",
    sold: "97"
  },
  {
    id: 7,
    image: Minipills,
    title: "Pill Box Mini Pill Case 2/3 Grids Tablet Pill Organizer Case",
    description: "Portable Pill Organizer",
    price: "150.00",
    rating: "5.0",
    reviews: "60",
    sold: "97"
  },
  {
    id: 8,
    image: Siddhalepa,
    title: "Siddhalepa Herbal Ayurvedic Sri Lankan Ayurvedic Balm",
    description: "Traditional Herbal Balm",
    price: "50.00",
    rating: "5.0",
    reviews: "60",
    sold: "97"
  },
  {
    id: 9,
    image: Plate,
    title: "Pill Counting Tray Tablet Counter Dispenser Pills Plate Splitter with Spatula Tool",
    description: "Professional Pharmacy Tool",
    price: "400.00",
    rating: "5.0",
    reviews: "60",
    sold: "97"
  }
];

const Pharmacy = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <main className="flex-grow bg-gradient-to-r from-blue-50 to-green-50 bg-cover bg-center" style={{ backgroundImage: `url(${PharmacyBg})` }}>
        <div className="container mx-auto px-4 py-8 ">
          <div>
            <button
              onClick={() => window.history.back()}
              className="flex items-center font-arial px-2 py-1 ml-16 rounded transition-colors mb-11 -mt-4 font-semibold bg-[#000080] text-white"
            >
              <FaChevronLeft />
              Back
            </button>

            <div className="relative flex items-center w-[498px] h-[44px] ml-[924px] -mt-[74px] border border-gray-400 shadow-md rounded-full bg-white">
              <input
                type="text"
                placeholder="Search Here"
                className="pl-4 w-full h-full text-center font-[Arial] text-gray-400 bg-transparent focus:outline-none placeholder-gray-400"
              />
              <button className="absolute right-2 bg-[#B3B3B3] rounded-full p-2 w-14 h-8 flex items-center justify-center ">
                <IoIosSearch className="w-6 h-6 text-white stroke-[15]" />
              </button>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-7 ml-16">
            {products.map((product) => (
              <ProductCard key={product.id} {...product} />
            ))}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Pharmacy;
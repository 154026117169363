import React from 'react';

const DoctorDashboard = () => {
  return (
    <div className="bg-transparent border-2 border-[#C0C2C2] md:border-0 md:border-transparent p-2 md:p-0">
        <h1 className="text-[20px] uppercase font-semibold text-left">Dashboard</h1>
        {/* Main Container */}
        <div className="mt-7 mb-1">
            {/* Container 01 */}
            <div className="grid grid-cols-1 w-full gap-4 md:grid-cols-2 lg:grid-cols-3">
                {/*  */}
                <div className="w-full bg-white p-2 h-32 hover:scale-[102%] md:hover:scale-105  transition-all duration-300 bg-gradient-to-tr from-[#D5F3FF] to-white shadow-lg">
                </div>
                {/*  */}
                <div className="w-full bg-white p-2 h-32 hover:scale-[102%] md:hover:scale-105  transition-all duration-300 bg-gradient-to-tr from-[#D5F3FF] to-white shadow-lg">
                </div>
                {/*  */}
                <div className="w-full bg-white p-2 h-32 hover:scale-[102%] md:hover:scale-105  transition-all duration-300 bg-gradient-to-tr from-[#D5F3FF] to-white shadow-lg">
                </div>
            </div>
            {/* Container 02 */}
            <div className="grid grid-cols-1 mt-3 gap-4 lg:grid-cols-5">
                {/*  */}
                <div className="lg:col-span-3 w-full h-[350px]">
                    <h2 className="text-left font-medium text-md text-black">Good Morning  <span className="font-bold text-lg text-[#3E36B0]">Dr. Rasika Dissanayaka!</span></h2>
                    {/*  */}
                    <div className="bg-gradient-to-r from-[#D5F3FF] to-white w-full h-[310px] shadow-lg mt-3">
                    </div>
                </div>
                {/*  */}
                <div className="lg:col-span-2 w-full h-[350px] bg-white shadow-lg">
                </div>
            </div>
            {/* Container 03 */}
            <div className="grid grid-cols-1 gap-4 mt-3 lg:grid-cols-2">
                {/*  */}
                <div className="w-full h-[400px] bg-gradient-to-tr from-[#D5F3FF] to-white shadow-lg">
                </div>
                {/*  */}
                <div className="w-full bg-gradient-to-tl h-[400px] from-[#D5F3FF] to-white shadow-lg">
                </div>
            </div>
        </div>
    </div>
  );
};

export default DoctorDashboard;
import React from 'react'
import registerbg from '../img/RegisterBg.png';
import { IoIosArrowBack } from "react-icons/io";
import image1 from '../img/Google.png';
import image2 from '../img/Apple.png';
import image3 from '../img/Facebook.png';
import { IoMdCloseCircleOutline } from "react-icons/io";



export default function Register() {
    const style = {
        backgroundImage: `url(${registerbg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        /*height: '150vh',
        width: '100%',*/
      };
        
  return (
    <div style={style} className='xl:w-[100%] xl:h-[900px] h-full'>
      <div className='bg-gradient-to-r from-[#98DED9D6] to-[#EEECFCD6] w-full xl:h-[900px] h-[1000px]'>
        {/* Back Button*/ }
        <br /><br />
        <button 
        onClick={() => window.history.back()}
        className='bg-[#00008B] w-[100px] font-bold ml-[20px] xl:ml-[100px] rounded text-lightColor text-center shadow-custom-dark hover:bg-[#354da2] flex p-2  ' >
          <IoIosArrowBack className='mt-[5px] ml-[12px] font-bold'/>
            Back
       </button>

       {/* Form section */}
       <div className='w-[650px] h-[700px] ml-[30px] mt-[20px] xl:flex md:flex flex-row xl:ml-[250px] '>
        <form action="">
          
        <label htmlFor="" className='table-row text-[#7465B9] xl:text-[17px] text-[13px]'>Full Name</label>
        <label htmlFor="" className='table-row text-[#7465B9] xl:text-[14px] text-[13px] font-[Calibri]'>(මුල නම සහ අග නම ඇතුළත් කරන්න.)</label>
        
        <div className='flex'>
        <div className='flex'>
        <input type="text" 
        className='mt-[5px] pl-4 py-1.5 xl:w-[250px] w-[200px] h-[50px] placeholder-[#7465B9] placeholder:text-sm border-[1px] border-[#7465B9] rounded-[9px]' 
        placeholder='First Name' required />
        <IoMdCloseCircleOutline className='absolute text-[#7465B9] cursor-pointer xl:ml-[220px] ml-[170px] mt-6'/>
        </div>
        <div className='flex ml-3'>
        <input type="text" 
        className='mt-[5px] ml-[10px] xl:w-[250px] w-[200px] pl-4 py-1.5 placeholder-[#7465B9] placeholder:text-sm border-[1px] border-[#7465B9] rounded-[9px]' 
        placeholder='Last Name' required />
        <IoMdCloseCircleOutline className='absolute text-[#7465B9] cursor-pointer xl:ml-[230px] ml-[180px] mt-6'/>
        </div>
        </div>
        
        <div className='mt-[20px]'>
        <div className='flex'>
          
        <div>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[17px] text-[13px] '>Date of Birth</label>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[14px] text-[13px] '>(උපන්දිනය ඇතුළත් කරන්න.)</label>
          <input type="date" 
          placeholder="MM/DD/YYYY" required
          className='mt-[5px] pl-4 py-1.5 xl:w-[250px] w-[200px] h-[50px] placeholder-[#7465B9] placeholder:text-sm border-[1px] border-[#7465B9] rounded-[9px]'  />
        </div>
        
        <div className='ml-5'>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[17px] text-[13px] '>Gender</label>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[14px] text-[13px]'>(ස්ත්‍රී පුරුෂ භාවය.)</label>
          <div className='bg-white mt-[5px] pl-4 py-2 xl:w-[250px] w-[200px] h-[50px] placeholder-[#7465B9] placeholder:text-[10px] border-[1px] border-[#7465B9] rounded-[9px]'>
          <input type="radio" id="female" name="gender"  /><label htmlFor="" className='text-[#7465B9] mt-4 text-[14px] ml-2'>Female</label>
          <input type="radio" id="male" name="gender" className='ml-10'/><label htmlFor=""className='text-[#7465B9] text-[14px] ml-2'>Male</label> 
          </div>
        </div>
        
        </div>
        </div>
        
        
        <div className='mt-[20px]'>
        <div className='flex'>
          
        <div>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[17px] text-[13px]'>Phone Number</label>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[14px] text-[13px]'>(දුරකථන අංකය ඇතුළත් කරන්න.)</label>
          <input type="text" 
          placeholder="" required
          className='mt-[5px] pl-4 py-1.5 xl:w-[250px] w-[200px] h-[50px] placeholder-[#7465B9] placeholder:text-sm border-[1px] border-[#7465B9] rounded-[9px]'  />
        </div>
        
        <div className='ml-5'>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[17px] text-[13px]'>Email Address</label>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[14px] text-[13px]'>(විද්‍යුත් ලිපිනය ඇතුළත් කරන්න.)</label>
          <input type="email" 
          placeholder="" required
          className='mt-[5px] pl-4 py-1.5 xl:w-[250px] w-[200px] h-[50px] placeholder-[#7465B9] placeholder:text-sm border-[1px] border-[#7465B9] rounded-[9px]'  />
        </div>
        
        </div>
        </div>
        
        <div className='mt-[20px]'>
        <div className='flex'>
          
        <div>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[17px] text-[13px]'>Address</label>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[14px] text-[13px]'>(ලිපිනය ඇතුළත් කරන්න.)</label>
          <input type="text" 
          placeholder="" required
          className='mt-[5px] pl-4 py-1.5 xl:w-[250px] w-[200px] h-[50px] placeholder-[#7465B9] placeholder:text-sm border-[1px] border-[#7465B9] rounded-[9px]'  />
        </div>
        
        <div className='ml-5'>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[17px] text-[13px]'>NIC Number</label>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[14px] text-[13px]'>(ජාතික හැඳුනුම්පත් අංකය.)</label>
          <input type="text" 
          placeholder="" required
          className='mt-[5px] pl-4 py-1.5 xl:w-[250px] w-[200px] h-[50px] placeholder-[#7465B9] placeholder:text-sm border-[1px] border-[#7465B9] rounded-[9px]'  />
        </div>
        
        </div>
        </div>
        
        <div className='mt-[20px]'>
        <div className='flex'>
          
        <div>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[17px] text-[13px]'>User Name</label>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[14px] text-[13px]'>(පරිශීලක නාමය ඇතුළත් කරන්න.)</label>
          <input type="text" 
          placeholder="" required
          className='mt-[5px] pl-4 py-1.5 xl:w-[250px] w-[200px] h-[50px] placeholder-[#7465B9] placeholder:text-sm border-[1px] border-[#7465B9] rounded-[9px]'  />
        </div>
        
        <div className='ml-5'>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[17px] text-[13px]'>Password</label>
          <label htmlFor="" className='table-row text-[#7465B9] xl:text-[14px] text-[13px]'>(නව මුරපදයක් යොදන්න.)</label>
          <input type="password" 
          placeholder="" required
          className='mt-[5px] pl-4 py-1.5 xl:w-[250px] w-[200px] h-[50px] placeholder-[#7465B9] placeholder:text-sm border-[1px] border-[#7465B9] rounded-[9px]'  />
        </div>
        
        </div>
        </div>
        
        </form>
        
      
          <div className='xl:ml-[50px] ml-[25px] md:ml-[65px]'>
              <div className='w-[600px] xl:mt-[25%] mt-[50px] flex flex-col xl:items-center justify-center'>
                <button className='p-2.5 rounded mt-2 bg-[#7465B9] w-[350px] hover:bg-[#8050ac] text-lightColor text-xl font-bold'>
                Sign up
                </button>
          
                <div className="flex items-center w-full mt-10 xl:justify-center">
                  <div className="border-t border-[#7465B8] w-[110px]"></div>
                  <span className="text-[#261C53] ml-3 mr-3 xl:mb-0">Or continue</span>
                  <div className="border-t border-[#7465B8] w-[110px]"></div>
                </div>

                <div className='flex mt-8 space-x-4 xl:justify-center xl:items-center'>
                  <img src={image1} alt="Google" className='w-[110px]'/>
                  <img src={image2} alt="Mac" className='w-[110px]'/>
                  <img src={image3} alt="Facebook" className='w-[110px]'/>
                </div>
              </div>
           </div>
       </div>
       
    </div>
  </div>
  )
}


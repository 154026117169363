import React, { useState, useRef } from 'react';

//components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PatientsAppointment from './PatientsAppointment';
import Message from '../../components/Message';
import DoctorDashboard from './DoctorDashboard';
import Invoice from '../../components/Invoice';
import ProfileSettings from './ProfileSettings';
import Calendar from '../../components/Calendar';

//images
import profilebg from '../../img/doctorsProfileBg.png';
import profile from '../../img/doctorsPageIcon.png';
import ScheduleBg from '../../assest/images/ScheduleBg.png';

//icons
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { AiOutlineLogout } from "react-icons/ai";
import { MdFormatAlignJustify, MdOutlineMessage, MdEventNote, MdOutlineAccountCircle } from "react-icons/md";
import { TfiReceipt } from "react-icons/tfi";
import { IoCaretDownSharp } from "react-icons/io5";
import { FaChevronLeft } from "react-icons/fa6";

const ScheduleTime = () => {
  const [activeMenu, setActiveMenu] = useState('scheduleTiming');
  const rightSideRef = useRef(null);

  const doctordetails = {
    name: 'Dr. Rasika Dissanayaka',
    field: 'DERMATOLOGIST',
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);

    if (rightSideRef.current) {
      rightSideRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const getSidebarHeightClass = () => {
    if (activeMenu === 'scheduleTiming') {
      return 'h-[1198px]'; // Specific height for scheduleTiming page
    }
    return 'h-auto'; // Default height for all other pages
  };

  return (
    <div className="min-h-screen w-full h-full bg-cover bg-center flex flex-col" style={{ backgroundImage: `url(${ScheduleBg})` }} >
      <Header />
      <div className="grid w-full h-auto grid-cols-1 mt-2 md:grid-cols-7">
        {/* left side */}
        <div className={`col-span-1 md:col-span-2 border-2 border-[#98DED9] m-2 md:ml-3 md:mt-3 ${getSidebarHeightClass()}`}>
          {/* profile */}
          <div className="bg-[#F1EFFC] w-full h-auto border-b-2 border-b-[#98DED9]">
            <img src={profilebg} alt="profile_background" className="w-full h-[229px] object-cover" />
            <div className="pb-2">
              <img src={profile} alt="Profile_image" className="object-cover w-32 h-32 mx-auto -mt-16 rounded-full shadow-lg" />
              <h2 className="uppercase text-center mt-2 font-bold text-[25px] text-[#161D6F]">{doctordetails.name}</h2>
              <h4 className="uppercase text-center -mt-2 font-bold text-[20px] text-[#7465B9]">{doctordetails.field}</h4>
            </div>
          </div>
          {/* navbar */}
          <div className="my-12 ml-6">
            <ul className="text-[20px] font-bold">
              {/* menu items */}
              {[
                { key: 'dashboard', label: 'Dashboard', icon: <MdFormatAlignJustify className="mr-5 text-2xl" /> },
                { key: 'appointment', label: 'Appointment', icon: <BsFillJournalBookmarkFill className="mr-5 text-xl" /> },
                { key: 'scheduleTiming', label: 'Schedule Timing', icon: <MdEventNote className="mr-5 text-2xl" /> },
                { key: 'messages', label: 'Messages', icon: <MdOutlineMessage className="mr-5 text-2xl" /> },
                { key: 'invoice', label: 'Invoice', icon: <TfiReceipt className="mr-5 text-2xl" /> },
                { key: 'profileSettings', label: 'Profile Settings', icon: <MdOutlineAccountCircle className="mr-5 text-2xl" /> },
                { key: 'logout', label: 'Logout', icon: <AiOutlineLogout className="mr-5 text-2xl" /> },
              ].map((item) => (
                <li key={item.key} className="my-7">
                  <button
                    onClick={() => handleMenuClick(item.key)}
                    className={`w-full text-left no-underline focus:outline-none hover:text-[#5f44c8] rounded-lg
                        ${activeMenu === item.key ? 'text-[#00FF03] hover:text-[#4ae14c]' : 'text-[#261C53]'}`}
                  >
                    <div className="flex items-center">
                      {item.icon}
                      <span className="sidebar-title">{item.label}</span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* right side */}
        <div ref={rightSideRef} className="h-auto col-span-1 m-2 md:col-span-5 border-1 md:ml-3 md:mt-3">

          {activeMenu === 'scheduleTiming' && (


            <div className="p-4">
              <div className="flex justify-between items-center mb-6">
                {/* Right borders*/}
                <div
                  className="absolute w-0 h-[1110px] left-[464px] top-[241px] border-l-[2px] border-[#98DED9] rotate-180"
                >
                </div>

                <div
                  className="absolute w-[1054px] h-0 left-[464px] top-[240px] border-t-[2px] border-[#C0C2C2] rotate-180"
                >
                </div>
                <button
                  onClick={() => window.history.back()}
                  className="flex items-center font-arial px-1 py-1 rounded transition-colors font-semibold bg-[#000080] text-white mt-4 mb-6"
                >
                  <FaChevronLeft className="text-l mt-0.5" />
                  Back
                </button>
                <h2 className="text-[18px] font-bold text-[#27272A] flex items-center gap-2"
                >
                  DATE
                  < IoCaretDownSharp className="mr-2" />
                </h2>

              </div>
              <Calendar />

              {/* Available Events Section */}
              <div className="mt-8 ml-20 ">
                <h2 className="text-2xl font-bold mb-4 text-[#212224]">Available Events</h2>

                {/* Event buttons using flex layout */}
                <div className="flex flex-col space-y-3 max-w-[220px] grid-cols-2 gap-x-16">
                  {['Meeting', 'Operations', 'Lunch', 'Conference', 'Business Meeting'].map((event) => (
                    <div
                      key={event}
                      className="h-[44px] flex items-center bg-[#5478BD] border border-[#161D6F] rounded-[4px]"
                    >
                      <span className="text-white font-[Arial] font-normal text-[17px] pl-4">
                        {event}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Channeling section */}
              <div
                className="absolute w-[275px] h-[64px] left-[885px] top-[1070px] 
                            text-black font-[Calibri] font-normal text-[17px] leading-[18px] capitalize"
              >
                Channeling
              </div>


              {/* Checkbox section */}
              <div className="-mt-8 pl-96 flex items-center">
                <input type="checkbox" className="w-[18px] h-[18px] mr-2" />
                <span className="text-black font-[Calibri] text-[18px] leading-[24px] capitalize">
                  Remove After Drop
                </span>
              </div>
              
              <div
                className="absolute w-[1518px] h-0 left-[0px] top-[1350px] border-t-[2px] border-[#C0C2C2] rotate-180 "                            
              >
              </div>
              <div className="pb-28">
              </div>
            </div>
          )}

          {/* Other menu components */}
          {activeMenu === 'dashboard' && <DoctorDashboard />}
          {activeMenu === 'appointment' && <PatientsAppointment />}
          {activeMenu === 'messages' && <Message />}
          {activeMenu === 'invoice' && <div><Invoice /></div>}
          {activeMenu === 'profileSettings' && <ProfileSettings />}
          {activeMenu === 'logout' && <div>Welcome to the Logout!</div>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ScheduleTime;
import React from 'react'

const InstructionNav = () => {
    return (
        <div>
            <div className="flex flex-row items-center px-[35px] h-[85px] sm:h-[85px] md:h-[55px] bg-[#7BB4B0]" >
                <h1 className='font-normal sm:font-bold text-sm'>ඔබගේ වෛද්‍යවරයා හමුවීමට වෙලාවක් වෙන් කර ගැනීම සඳහා <span className='text-red'>Make Appointment </span>යටතේ අදාළ වෛද්‍යවරයා පැමිණෙන ආසන්නතම දිනය ක්ලික් කර හමුවීම වෙන් කරවාගන්න</h1>
            </div>

            <div className="flex flex-row items-center px-[35px] h-[55px] sm:h-[45px] md:h-[45px] bg-[#809BEF]">
                <h1 className='font-normal sm:font-bold text-sm'><span className='text-red'>OPN - </span>වෛද්‍යවරයා මෙම මොහොතේ පරීක්ෂා කරන රෝගී අංක මෙහි සඳහන් වේ.</h1>
            </div>
        </div>
    )
}

export default InstructionNav
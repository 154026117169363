import React, { useState, useCallback } from 'react';

const EventModal = ({ isOpen, onClose, onSave, selectedDate }) => {
  const [eventData, setEventData] = useState({
    date: selectedDate ? formatDateForInput(selectedDate) : '',
    startTime: '09:00',
    endTime: '11:00',
  });

  if (!isOpen) return null;

  function formatDateForInput(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-96">
        <h2 className="text-xl font-semibold mb-4">Add New Event</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Date
            </label>
            <input
              type="date"
              className="w-full p-2 border rounded"
              value={eventData.date}
              onChange={(e) => setEventData({ ...eventData, date: e.target.value })}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Time
              </label>
              <input
                type="time"
                className="w-full p-2 border rounded"
                value={eventData.startTime}
                onChange={(e) => setEventData({ ...eventData, startTime: e.target.value })}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                End Time
              </label>
              <input
                type="time"
                className="w-full p-2 border rounded"
                value={eventData.endTime}
                onChange={(e) => setEventData({ ...eventData, endTime: e.target.value })}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-2 mt-6">
            <button
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-[#3455EA] text-white rounded hover:bg-[#3455EA]"
              onClick={() => {
                onSave({
                  date: eventData.date,
                  time: `${eventData.startTime} - ${eventData.endTime}`
                });
                onClose();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState('Month');
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  // Get month details
  const getMonthDetails = useCallback(() => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const startingDay = firstDay.getDay();
    const totalDays = lastDay.getDate();
    
    return { year, month, startingDay, totalDays };
  }, [currentDate]);

  // Format date to YYYY-MM-DD
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Generate calendar days
  const generateCalendarDays = useCallback(() => {
    const { startingDay, totalDays } = getMonthDetails();
    const days = [];
    
    // Previous month's days
    for (let i = 0; i < startingDay; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), -startingDay + i + 1);
      days.push({
        date,
        formattedDate: formatDate(date),
        isCurrentMonth: false,
        events: events.filter(event => event.date === formatDate(date))
      });
    }
    
    // Current month's days
    for (let i = 1; i <= totalDays; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
      days.push({
        date,
        formattedDate: formatDate(date),
        isCurrentMonth: true,
        events: events.filter(event => event.date === formatDate(date))
      });
    }
    
    // Next month's days
    const remainingDays = 42 - days.length;
    for (let i = 1; i <= remainingDays; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, i);
      days.push({
        date,
        formattedDate: formatDate(date),
        isCurrentMonth: false,
        events: events.filter(event => event.date === formatDate(date))
      });
    }
    
    return days;
  }, [currentDate, events, getMonthDetails]);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT'];

  const goToPreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const goToNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const goToToday = () => {
    setCurrentDate(new Date());
  };

  const handleAddEvent = (newEvent) => {
    setEvents([...events, { ...newEvent, id: Date.now() }]);
  };

  const openAddEventModal = (date = null) => {
    setSelectedDate(date);
    setIsModalOpen(true);
  };

  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      {/* Header */}
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
        <div className="flex">
          <button 
            className="p-2 bg-[#3455EA] text-white rounded-l hover:bg-[#2e46b3] text-[20px] border-r border-white"
            onClick={goToPreviousMonth}
          >
            &lt;
          </button>
          <button 
            className="p-2 bg-[#3455EA] text-white rounded-r hover:bg-[#2e46b3] text-[20px]"
            onClick={goToNextMonth}
          >
            &gt;
          </button>
        </div>

          <button 
            className="px-4 py-2 bg-[#3455EA] text-white rounded hover:bg-[#2e46b3] font-[Calibri] text-[20px]"
            onClick={goToToday}
          >
             Today
          </button>
        </div>

        {/* Center section with month/year */}
        <div className="flex-1 text-center">
          <h2 className="font-semibold font-[Calibri] text-[25px] text-black">
            {months[currentDate.getMonth()]} {currentDate.getFullYear()}
          </h2>
        </div>
        
        <div className="flex items-center space-x-2">
          <button 
            className={`px-4 py-2 rounded font-[Calibri] text-[20px] ${
              view === 'Month' 
                ? 'bg-[#3455EA] text-white' 
                : 'bg-[#3455EA] text-white hover:bg-[#2e46b3]'
            }`}
            onClick={() => setView('Month')}
          >
            Month
          </button>
          <button 
            className={`px-4 py-2 rounded font-[Calibri] text-[20px] ${
              view === 'Week' 
                ? 'bg-[#3455EA] text-white' 
                : 'bg-[#3455EA] text-white hover:bg-[#2e46b3]'
            }`}
            onClick={() => setView('Week')}
          >
            Week
          </button>
          <button 
            className={`px-4 py-2 rounded font-[Calibri] text-[20px] ${
              view === 'Day' 
                ? 'bg-[#3455EA] text-white' 
                : 'bg-[#3455EA] text-white hover:bg-[#2e46b3]'
            }`}
            onClick={() => setView('Day')}
          >
            Day
          </button>
          <button 
            className="px-4 py-2 bg-[#00FFEE] text-[#5A5A5A] font-[Calibri] text-[20px] font-bold rounded hover:bg-cyan-500 hover:text-white"
            onClick={() => openAddEventModal(new Date())}
          >
            Add Event
          </button>
        </div>
      </div>

      {/* Calendar Grid */}
      <div className="border border-gray-200 rounded shadow-lg">
        {/* Weekday headers */}
        <div className="grid grid-cols-7 bg-[#00FFEE] text-black font-[Calibri] text-[18px]">
          {weekDays.map(day => (
            <div key={day} className="p-2 text-center font-semibold">
              {day}
            </div>
          ))}
        </div>

        {/* Calendar days */}
        <div className="grid grid-cols-7 font-semibold text-right">
          {generateCalendarDays().map((day, index) => (
            <div
              key={index}
              className={`min-h-24 p-2 border-t border-l border-[#636161] border-[0.75px] relative cursor-pointer hover:bg-gray-50 ${
                day.isCurrentMonth ? 'bg-white' : 'bg-[#F0F0F0]'
              } ${isToday(day.date) ? 'bg-white' : ''}`}
              onClick={() => openAddEventModal(day.date)}
            >
              <span className={`inline-flex w-6 h-6 items-center justify-center rounded-full ${
                isToday(day.date) 
                  ? 'bg-[#3455EA] text-white' 
                  : day.isCurrentMonth 
                    ? 'text-[#948F8F]' 
                    : 'text-[#948F8F]'
              }`}>
                {day.date.getDate()}
              </span>
              
              {/* Events */}
              <div className="mt-1 space-y-1">
                {day.events.map(event => (
                  <div 
                    key={event.id}
                    className="bg-[#3455EA] text-white text-xs p-1 rounded cursor-pointer hover:bg-[#3455EA]"
                    onClick={(e) => {
                      e.stopPropagation();
                      // Handle event click
                    }}
                  >
                    {event.time}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Event Modal */}
      <EventModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleAddEvent}
        selectedDate={selectedDate}
      />
    </div>
  );
};

export default Calendar;
import React from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PatientLog from '../../components/PatientLog';
export default function PatientLogin() {
  return (
    <div>
        <Header/>
          <PatientLog/>
        <Footer/>
    </div>
  )
}
